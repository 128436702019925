<template>
    <div class="chart-holder">
        <div class="l-padded datepicker">
            <DateRangeInput v-model="customRange" />
        </div>

        <div class="chart">
            <div v-if="isLoading" class="l-stack l-center l-padded">
                <VSpinner size="medium" line-fg-color="#000" :speed="1" />
            </div>

            <div
                v-if="!hasData && !isLoading"
                class="l-stack l-center l-padded"
            >
                <span>{{ $t('nodata') }}</span>
            </div>

            <ApexChart
                v-if="!isLoading && hasData"
                height="420px"
                :options="chartOptions"
                :series="series"
            />

            <div
                v-if="!isLoading && hasData"
                class="l-inline l-padded l-justify-end"
            >
                <span>
                    {{ $t('workinghours') }}:
                    <strong>{{ workingHoursHumanized }}</strong>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment-timezone'
import VSpinner from 'vue-simple-spinner'
import ApexChart from 'vue-apexcharts'

import { httpHelper } from '@/utils'
import ChartHelper from '@/mixins/ChartHelper'
import DateRangeInput from '@/components/DateRangeInput'
import UrlHelper from '@/mixins/UrlHelper'

const dataTypes = [
    'voltage_0',
    'voltage_1',
    'voltage_2',
    'voltage_3',
    'voltage_4',
]

const mockData = [
    {
        timestamp: '2021-11-30T15:30',
        sensor_data: {
            voltage_0: 7200,
            voltage_1: 7200,
            voltage_2: 6300,
            voltage_3: 2100,
            voltage_4: 12000,
        },
    },
    {
        timestamp: '2021-11-30T15:00',
        sensor_data: {
            voltage_0: 7000,
            voltage_1: 8000,
            voltage_2: 6000,
            voltage_3: 2000,
            voltage_4: 11000,
        },
    },
    {
        timestamp: '2021-11-30T14:30',
        sensor_data: {
            voltage_0: 8000,
            voltage_1: 8500,
            voltage_2: 7000,
            voltage_3: 4000,
            voltage_4: 11200,
        },
    },
    {
        timestamp: '2021-11-30T14:00',
        sensor_data: {
            voltage_0: 8400,
            voltage_1: 8200,
            voltage_2: 6000,
            voltage_3: 5100,
            voltage_4: 11300,
        },
    },
]

export default {
    name: 'AssetChartsView',
    components: {
        ApexChart,
        DateRangeInput,
        VSpinner,
    },
    mixins: [ChartHelper, UrlHelper],
    props: {
        id: {
            type: [String, Number],
            required: true,
        },
        simulated: {
            type: Boolean,
        },
    },
    data() {
        return {
            series: [
                {
                    data: [],
                },
            ],
            chartOptions: {
                chart: {
                    type: 'rangeBar',
                },
                plotOptions: {
                    bar: {
                        horizontal: true,
                    },
                },
                xaxis: {
                    type: 'datetime',
                },
                tooltip: {
                    shared: false,
                    x: {
                        format: 'dd.MM.yy HH:mm:ss',
                    },
                },
            },
            customRange: {
                startDate: moment()
                    .startOf('day')
                    .toDate(),
                endDate: moment()
                    .endOf('day')
                    .toDate(),
            },
            isLoading: false,
        }
    },
    computed: {
        hasData() {
            return this.series[0].data.length > 0
        },
        workingHoursHumanized() {
            const duration = this.series[0].data
                .filter(item => item.isOn)
                .reduce((accDuration, item) => {
                    const dateStart = moment(item.y[0])
                    const dateEnd = moment(item.y[1])

                    return accDuration.add(
                        moment.duration(dateEnd.diff(dateStart))
                    )
                }, moment.duration(0))

            const hours = duration.asHours()
            duration.subtract(parseInt(hours), 'hours')
            const minutes = duration.asMinutes()

            return (
                (parseInt(hours) < 10 ? '0' : '') +
                parseInt(hours) +
                'h ' +
                (parseInt(minutes) < 10 ? '0' : '') +
                parseInt(minutes) +
                'm'
            )
        },
    },
    watch: {
        customRange() {
            this.load()
        },
    },
    mounted() {
        this.keepAsQueryParams(true, {
            'customRange.startDate': {
                key: 'start',
                type: 'date',
            },
            'customRange.endDate': {
                key: 'end',
                type: 'date',
            },
        })
    },
    methods: {
        async load() {
            this.isLoading = true
            const results = this.simulated ? mockData : await this.loadData()
            this.setData(results)
            this.isLoading = false
        },
        async loadData() {
            let results = []

            const timestampMin = moment(this.customRange.startDate).format()
            const timestampMax = moment(this.customRange.endDate).format()

            let url =
                'measurements/?' +
                `tracker=${this.id}` +
                '&fields=timestamp,sensor_data' +
                `&timestamp_min=${encodeURIComponent(timestampMin)}` +
                `&timestamp_max=${encodeURIComponent(timestampMax)}` +
                `&limit=${process.env.VUE_APP_LIMIT_RECORDS_PER_REQUEST}`

            while (url) {
                const { data } = await httpHelper.get(url)
                results = [...results, ...data.results]
                url = data.next
            }

            const respBefore = await httpHelper.get(
                'measurements/?' +
                    `tracker=${this.id}` +
                    '&fields=timestamp,sensor_data' +
                    `&timestamp_max=${encodeURIComponent(timestampMin)}` +
                    '&limit=1'
            )
            const lastBefore = respBefore.data.results[0]
            if (lastBefore) {
                lastBefore.timestamp = timestampMin
                results = [...results, lastBefore]
            }

            const respAfter = await httpHelper.get(
                'measurements/?' +
                    `tracker=${this.id}` +
                    '&fields=timestamp,sensor_data' +
                    `&timestamp_min=${encodeURIComponent(timestampMax)}` +
                    '&limit=1'
            )
            const firstAfter = respAfter.data.results[0]
            if (firstAfter) {
                firstAfter.timestamp = timestampMax
                results = [firstAfter, ...results]
            }

            return results
        },
        setData(results) {
            const chartData = results
                .filter(item =>
                    dataTypes.some(type => type in item.sensor_data)
                )
                .map(item => [
                    item.timestamp,
                    dataTypes.every(type => item.sensor_data[type] >= 3000),
                ])
                .reverse()

            const dataOn = []
            const dataOff = []

            let lastSameDataIndex = 0
            while (lastSameDataIndex < chartData.length - 1) {
                const item = chartData[lastSameDataIndex]

                while (
                    lastSameDataIndex < chartData.length - 1 &&
                    chartData[lastSameDataIndex][1] === item[1]
                ) {
                    lastSameDataIndex++
                }

                const data = {
                    x: item[1] ? this.$t('on') : this.$t('off'),
                    isOn: item[1],
                    y: [
                        new Date(item[0]).getTime(),
                        new Date(chartData[lastSameDataIndex][0]).getTime(),
                    ],
                    fillColor: item[1] ? '#41b883' : '#d32f2f',
                }

                if (item[1]) {
                    dataOn.push(data)
                } else {
                    dataOff.push(data)
                }
            }

            this.series = [
                {
                    data: [...dataOn, ...dataOff],
                },
            ]
        },
    },
}
</script>

<i18n>
{
    "en": {
        "on": "OK",
        "off": "Not OK",
        "nodata": "No data for this time period",
        "workinghours": "Hours of operation"
    },
    "de": {
        "on": "Spannungen OK",
        "off": "Spannungen nicht OK",
        "nodata": "Keine Daten für diese Zeitperiode",
        "workinghours": "Betriebsstunden OK"
    },
    "fr": {
        "on": "OK",
        "off": "Pas OK",
        "nodata": "Aucune donnée pour cette période de temps",
        "workinghours": "Heures de fonctionnement"
    },
    "it": {
        "on": "OK",
        "off": "Not OK",
        "nodata": "Nessun dato per questo periodo di tempo",
        "workinghours": "Orario di funzionamento"
    }
}
</i18n>

<style lang="scss" scoped>
.chart-holder {
    display: flex;
}

.chart {
    flex-grow: 100;
    margin: 1rem 1rem 1rem 0;
    border-left: $style-border;
}

.datepicker {
    width: 20%;
    min-width: 350px;
}
@include respond-to('for-tablet-down') {
    .chart-holder {
        display: block;
    }

    .chart {
        border: none;
    }

    .datepicker {
        padding: 0 0 1rem;
        margin: 1rem 1rem 0;
        border-bottom: $style-border;
    }
}
</style>
